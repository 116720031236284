import React, { useEffect } from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'
import Comments from './Comments'
import Specimen from './Specimen'
import Result from './Result'
import MedicineHeader from './MedicineHeader'
import CovidPDF from '../Covid_PDF/CovidTemplate'
const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    fontFamily: "Helvetica",
    borderColor: 'black',
  },
  subReportTitle: {
    color: 'black',
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  GermTitle: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  SusProfileTitle:{
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  MedicineGroupName:{
    color: 'black',
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  }
});


class InvoiceItemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.invoice,
      line: 2,
      page: 1,
      CrossCheck: {},
      NextPage: 0,
      Garbage: [],
      counter: 0,
      page:0,
      DefaultComments:{
        "Cobas C311":"Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        "Cobas C111":"Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        "Cobas e411":"Method/Analyzer: Cobas e 411, fully automated Electrochemiluminescence immunoassay (ECLIA) based analyzer, from Roche Diagnostics, Switzerland.",
        "Blue Diver Instrument":"Method/Analyzer: Blue Diver Instrument, automated Immunodot analyzer, from D-tek, Belgium.",
        "BC - 5000":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "BC - 5150":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Cobas U411":"Method/Analyzer: Cobas u 411, automated Urine analyzer, from Roche Diagnostics, Switzerland.",
        "Cube 30 Touch":"Method/Analyzer: Cube 30 TOUCH, Automatic instrument for ESR, from Diesse Diagnostica Senese, Italy.",
        "GeneXpert":"Method/Analyzer: GeneXpert ® PCR system by Cepheid, USA.",
        "BC6200":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Compact X, Behnk Elektronik":"Method/Analyzer: Thrombolyzer Compact X, , fully automated coagulation analyzer, from Behnk Elektronik GmbH & Co. Germany.",
        "FANhp UBT":"Method/Analyzer: FANhp, An automated Urea Breath Testing analyzer, from Fischer Analysen Instrumente (FAN), Germany.",
        "MiniCap Sebia":"Method/Analyzer: MINICAP FLEX PIERCING, fully automated Capillary Electrophoresis System, from Sebia, France.",
        "Chorus Trio":"Method/Analyzer: Chorus TRIO Instrument, automated ELISA based immunoassay analyzer, from Diesse Diagnostica Senese Spa, Italy.",
        "Manual":"",
        "OST":"",
        "Microlab 300":"",
        "Alegria Instrument":"Method/Analyzer: Alegria Instrument, automated ELISA based immunoassay analyzer, from Orgentec Diagnostika GmbH, Germany.",
        "HG SWIFT":"Method/Analyzer: HG Swift, A loop mediated iso thermal amplification (LAMP), from Hibergene, Ireland.",
        "VIDAS":"Method/Analyzer: Automated immunoassay analyzer (VIDAS) from Biomerieux SA, France."
      },
      Machine:"",
      singleTestCounter:0,
      MachineCommentsToShow:[],
      ShowMachineComments:true,
    }
  }

  render() {

    return <View>
      
      {(Object.keys(this.state.data.Microbiology).map((key) => {

      var shiftMargin = 0
      var totalTests = 0
      this.state.line = this.state.line + 2
     
      var subGroupTitle = {}

      this.state.data.Microbiology[key].map((item) => {
        totalTests = totalTests + item.rowHeight
      })

      var FirstBox = this.state.line + this.state.data.Microbiology[key][0].rowHeight + 2

      if (FirstBox > 28) {
        this.state.line = 0
        this.state.NextPage = 1


      } else {
        this.state.NextPage = 0


      }
      if (key.includes("Complete") && this.state.data.Microbiology[key][0].prev1.length > 4) {
        this.state.line = this.state.line - 8
      }
      if (this.state.NextPage == 0) {

        this.state.Garbage.push(1)
      } else {
        this.state.Garbage = []
      }
      if (this.state.Garbage.length == 3) {
        this.state.NextPage = 1
        this.state.line = 0
      }

      if ((key.includes("Complete Blood Picture") || key.includes("Urine Routine Examination")) && this.state.counter > 0) {
        this.state.NextPage = 1
        this.state.line = 0
      }
      // Machine
    
      this.state.ShowMachineComments=false
      if(this.state.Machine!=this.state.data.Machine[key]){
        this.state.ShowMachineComments=true
        this.state.Machine=this.state.data.Machine[key]
        
      }
      console.log("Machine : ")
      console.log(this.state.data.Machine[key])
      var pasteMachineComm=this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]
      
      this.state.MachineCommentsToShow.push(pasteMachineComm)
   
      console.log(this.state.MachineCommentsToShow)
      // Germ Counter
      var GermCounter=0
      console.log(key)
      console.log(shiftMargin)
      console.log(this.state.NextPage)
      console.log(this.state.counter)
      console.log(Object.keys(this.state.data.Germs).length)
      console.log("Comments Flag")
      console.log(this.state.ShowMachineComments)
      console.log(this.state.data.MachineComments[key])
      this.state.counter++

      // Test counter
      console.log("Test List Length = ")
      if(this.state.data.Microbiology[key].length==1){
        this.state.singleTestCounter++
      }else{
        this.state.singleTestCounter=0
      }
      
     

      return <View>
                

            <View style={styles.tableContainer}>

              <View style={{
                marginTop: -10.2

              //  marginTop: -10 ye commit kiya hai 16-jan ko 1101470 2nd page pe empty aa rha tha
              }} >

                  {
                    this.state.counter > 1 ? <View break={true} key={key + "1"}></View> : null
                  }

                <View style={{
               flexDirection:"row",
                  width: "100%",
                  lineHeight: 1,
                  fontSize: 7,
                  paddingTop: 5,
                  //  backgroundColor:"red"
                
                }} key={key + "2133"}>
                  {/* width 100% ki jga 50% ki 16-nov-2022 asim ansari give 6401258 report */}
                    {/* length==0? column tha es ko row kiya  21-nov-2022 asim ansari give 8701319 report */}
                     {/*this.state.data.Microbiology[key].length==4?"34.5%" &&(this.state.data.MB_Germ1!="") :"46%", sir najam 9301382 18-dec-2022 MB_Germ1 me kch aae tab ye hai 342 pe b cond hai aisi report ki  */}
                {/* this.state.data.Microbiology[key].length==3? "57%" this cond apply when lenght of box is 3 sir nadeem 2301357 5-jan and below 270 commint empty   */}
                {/* && (this.state.data.MB_Germ1!="") and last waly option me 50% tha us ko 57% kr dya 8-jan 23 sunday 7201311 */}
                {/* this.state.data.Microbiology[key].length==4?"50%" 4 box wala issue kr rha tha 9801044 11-feb-2023 */}
                {/*14-jun-23 Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water" id is 0601637 11 box thy es ko row se column me kr dya hai aur alag se design kiya hai ye condition lga 14-jun-2023  */}
  {/*14-sep-2023(1/2) length 2 he thi but wo page se bahir ja rha tha for HVS so es ki condition lgae (only 2 pe 68% tha)  this.state.data.Microbiology[key].length==2 && this.state.data.Microbiology[key][0].title=="HVS Culture & Susceptibility Test"?"50%" */}
  {/* {console.log("check value "+JSON.stringify(this.state.data.Microbiology[key][0].title))}
                <View style={{flexDirection:Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water"?"column" : Object.keys(this.state.data.Germs).length==0 ? "row":"row", 
                width:this.state.data.Microbiology[key].length==2 && this.state.data.Microbiology[key][0].title=="HVS Culture & Susceptibility Test"?"50%":this.state.data.Microbiology[key].length==2?"69%":(this.state.data.Microbiology[key].length==4) && (this.state.data.MB_Germ1!="") ?"34.5%":this.state.data.Microbiology[key].length==3 && (this.state.data.MB_Germ1!="") ? "46%":this.state.data.Microbiology[key].length==4?"50%":Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water"?"100%":"57%"
                }}> */}
            {/* 1-july-2024 (1/2) (this.state.data.Microbiology[key].length==4) && (this.state.data.MB_Germ1=="") ?"25%" for 9902452  */}

                <View style={{
                  flexDirection:Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water"?"column" : Object.keys(this.state.data.Germs).length==0 ? "row":"row", 
              //  13-sep-2024 3802486 4 wali report merge hui thi
               width:this.state.data.invoice_id=="4802902"? "40%":this.state.data.Microbiology[key].length==2 && this.state.data.Microbiology[key][0].title=="HVS Culture & Susceptibility Test" && this.state.data.Microbiology[key][0].comments.includes("Gram")&& this.state.data.Microbiology[key][0].comments.includes("Nugent score")?"50%":this.state.data.Microbiology[key].length==2?"69%":(this.state.data.Microbiology[key].length==4) && (this.state.data.MB_Germ1!="") ?"34.5%":(this.state.data.Microbiology[key].length==4) && (this.state.data.MB_Germ1=="") ?"25%":this.state.data.Microbiology[key].length==4?"50%":this.state.data.Microbiology[key].length==3 && (this.state.data.MB_Germ1!="") ? "46%":Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water"?"100%"
             
                // this.state.data.invoice_id=="2702464"? "40%" 24-june-2024 MPL-IT group by sir asim (1/2)

               :"57%"
                // please not 57% 3 wali Microbiology[key].length==3 wali k lye kam kr rhi hai by default
                

// width:this.state.data.Microbiology[key].length==2?"69%":(this.state.data.Microbiology[key].length==4) && (this.state.data.MB_Germ1!="") ?"34.5%":this.state.data.Microbiology[key].length==3 && (this.state.data.MB_Germ1!="") ? "46%":this.state.data.Microbiology[key].length==4?"50%":Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water"?"100%":"57%"

                }}>
                    
                {/* <View style={{flexDirection: Object.keys(this.state.data.Germs).length==0 ? "row":"row", width:this.state.data.Microbiology[key].length==2?"69%":(this.state.data.Microbiology[key].length==4) && (this.state.data.MB_Germ1!="") ?"34.5%":this.state.data.Microbiology[key].length==3? "57%":"50%"}}> */}

                  {/* <View style={{flexDirection: Object.keys(this.state.data.Germs).length==0 ? "row":"row", width:"50%"}}> */}
                  {
                    this.state.data.Microbiology[key].map((item,index )=> {
                      this.state.line = this.state.line + item.resultLines

                      if (this.state.line > 28) {
                        this.state.line = 0
                      }

                      // return <View style={{ width: Object.keys(this.state.data.Germs).length==0 ? "72.5%" : "50%", marginTop:0.5}} >
                      //this.state.data.invoice_id=="5501367"? this condition apply for report 5501367 sir asim changes 3 dec 2022
                     // Object.keys(this.state.data.Germs).length==6?  this condition apply for 7601341 sir nadeem 6-dec-2022
                     //Object.keys(this.state.data.Germs).length==8?||this.state.data.MB_Germ1.includes("Streptococcus spp.")   this condition apply for 4101332 sir sasim 16-dec
                     //Object.keys(this.state.data.Germs).length==9?"72%" apply for sir asim 5202205 01-01-2023
                            {/* 4-june-2024 (1/2) this.state.data.invoice_id=="2202294"?"70%" */}

                      return <View style={{
                         width:this.state.data.invoice_id=="5501367"?Object.keys(this.state.data.Germs).length==0 ?  "72.5%" : "50%": Object.keys(this.state.data.Germs).length==0 ?  "100%" :Object.keys(this.state.data.Germs).length==6 ||Object.keys(this.state.data.Germs).length==8 ||Object.keys(this.state.data.Germs).length==9||this.state.data.MB_Germ1.includes("Streptococcus spp.")? "72%":this.state.data.invoice_id=="2202294"?"70%":"50%", marginTop:0.5}} >
{console.log("#############",JSON.stringify(this.state.data.Germs))}

{console.log("#############",Object.keys(this.state.data.Germs).length)}
{console.log("#############",this.state.data.MB_Germ1.includes("Streptococcus spp."))}

                        <View style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          lineHeight: 1,
                          fontSize: 7,
                          borderWidth: 0.5,
                          borderColor: "black",
                          // height:"80em",
                          height:Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water"?"85.5em":"80em",

                          //  backgroundColor:"red"
                        }} key={item.test_name}>

                          <Text style={{

                            width: '100%%',
                            textAlign: 'left',
                            paddingTop: 2,
                            paddingLeft: 8,
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 9,
                            // backgroundColor:"green"
                          }}>{item.test_name}</Text>
                          <Text style={{

                            width: '100%%',
                            textAlign: 'left',
                            paddingLeft: 10,
                            paddingTop: 5,
                            paddingRight: 10,
                            fontFamily: "Helvetica",
                            fontSize: 7.5,
                            lineHeight: 1.4

                          }}>
                            {/* 4-june-2024 (2/2) */}
                            {/* {item.result} */}
                            {index==3 && this.state.data.invoice_id=="2202294"?"Culture yielded   growth of ‘’Mycobacterium tuberculosis Complex’’ after incubation at 37oC.":item.result} 

                            </Text>


                        </View>


                      </View>
                    })

                  }
                  </View>

                </View>
             {
               Object.keys(this.state.data.Germs).length==0 ? <View>
                {/*this.state.data.comments[key] !=""? this cond apply ager hum ne pathologist reamrk na show krny hon ye commint uncommit kr den ge sir nadeem 2301357 5-jan   */}
                  {/* {this.state.data.comments[key] !=""? */}
                  <View style={{
                    paddingTop:10,
                    paddingLeft:10,
                    marginTop:10,
                    paddingBottom:10,
                    backgroundColor:"#eeeeee",
                    position:Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water"?"absolute":"relative",

                    // backgroundColor:"red",
                    borderWidth:0.5,
                     // add this line to show comment in full width 21-nov-2022 sir asim 9901079 3 box wla ni mila us ki b lgani hai
                    //  width:Object.keys(this.state.data.Germs).length==0 ? this.state.data.Microbiology[key].length==2?"100%":this.state.data.Microbiology[key].length==4? "200%":"0%":this.state.data.Microbiology[key].length==2?"138%":this.state.data.Microbiology[key].length==4? "200%":"0%",
                    //this.state.data.invoice_id=="5501367"? this condition apply for report 5501367 sir asim changes 3 dec 2022
                   // :this.state.data.Microbiology[key].length==3?"171% this cond apply if 3 box then remarks box show according change sir nadeem 2301357 5-jan-2023 "
                  //14-sep-2023 (2/2)  this.state.data.Microbiology[key].length==2 && this.state.data.Microbiology[key][0].title=="HVS Culture & Susceptibility Test"?"100%" ye 2 pe 138% tha now ab 2 pe + HSV ho to 100% kr dya without logo me zyada detail likhi
            // {/* 1-july-2024 (2/2) (this.state.data.Microbiology[key].length==4) && (this.state.data.MB_Germ1=="") ?"100%" for 9902452  */}
              
                  width:this.state.data.invoice_id=="5501367" ? this.state.data.Microbiology[key].length==2?"100%":this.state.data.Microbiology[key].length==4? "200%":"0%":this.state.data.Microbiology[key].length==2 && this.state.data.Microbiology[key][0].title=="HVS Culture & Susceptibility Test"&&this.state.data.Microbiology[key][0].comments.includes("Gram")&&this.state.data.Microbiology[key][0].comments.includes("Nugent score")?"100%":this.state.data.Microbiology[key].length==2?"138%":(this.state.data.Microbiology[key].length==4) && (this.state.data.MB_Germ1=="") ?"100%":this.state.data.Microbiology[key].length==4? "200%":
                // this.state.data.invoice_id=="2702464"?"120%" 24-june-2024 MPL-IT group by sir asim (2/2)

                this.state.data.invoice_id=="4802902"?"120%":this.state.data.Microbiology[key].length==3?"171%"

                  :Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water"?"100%":"0%",
                    // width:this.state.data.invoice_id=="5501367" ? this.state.data.Microbiology[key].length==2?"100%":this.state.data.Microbiology[key].length==4? "200%":"0%":this.state.data.Microbiology[key].length==2?"138%":this.state.data.Microbiology[key].length==4? "200%":this.state.data.Microbiology[key].length==3?"171%":Object.keys(this.state.data.Germs).length==0 &&key=="Bacteriological Examination of Water"?"100%":"0%",
                   
                 
                 }}>
                  <Text style={{
                     fontFamily: 'Helvetica-Bold',
                     fontSize: 8
                  }}>{"Pathologist Remarks"}</Text>
                  <View style={{
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: "100%",
                      fontSize: 7,
                      paddingTop: 10,
                      // backgroundColor:"green"
                      

                    }}
                    >
                      {
                        this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                          this.state.line = this.state.line + 1
                          var space = this.state.line > 28
                          if (data.length > 200) {
                            this.state.line = this.state.line + 1
                          }
                          console.log("Second Last Comments")
                          if (data == "") {
                            return <View><Text>{""}</Text></View>
                          }


                          if (space) {

                            return <View style={{
                              marginTop: 1,
                              width: "100%",
                          // paddingRight:this.state.data.comments[key].includes("Eosinophilia")?"55%":"0%"

                            }}>
                              <View></View>
                              <Text style={{
                                textAlign: "left"
                              }}>{data}</Text>
                            </View>

                          }

                          return <View style={{
                            marginTop: 1,
                            width: "100%",
                          }}>
                            <View></View>
                            <Text style={{
                              textAlign: "left"
                            }}>{data}</Text>
                          </View>
                        })
                      }

                    </View>
                 </View>
                  {/* :<></>} */}
               </View>
               :
    (this.state.data.MB_Germ1!="" &&this.state.data.MB_Germ2=="" && this.state.data.MB_Germ3=="" && this.state.data.MB_Germ4=="" && this.state.data.MB_Germ5=="" )
    ?
               <View>
                 <View style={{marginTop:"5em"}}>
                <Text style={styles.GermTitle}>{this.state.data.MB_Germ1}</Text>
                  <Text style={styles.SusProfileTitle}>{"SUSCEPTIBILITY PROFILE"}</Text>
                </View>
                  <View style={{ flexDirection:"row", width:"100%"}}>
                    <View style={{width:"45%"}}>
                      <View style={{width:"100%"}}>
                      {this.state.data!=undefined ? <MedicineHeader  data={this.state.data}/> : null}
                      </View>

                      {Object.keys(this.state.data.Germs).map((germKey) => {
                      
                        if(GermCounter<4){
                          GermCounter++;
                        
                        }else{
                          GermCounter=100
                          return <View></View>
                        }
                        return <View style={{ marginTop: 5 }}>
                          <Text style={styles.MedicineGroupName}>{germKey}</Text>
                          {
                            this.state.data.Germs[germKey].map((item) => {
                             
                              return <View>

                                <View style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  borderWidth: 0.5,
                                  lineHeight: 1,
                                  fontSize: 7,
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  margin: 1
                                }} key={item.Medicine}>

                                  <Text style={{

                                    width: '80%',
                                    textAlign: 'left',
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    paddingLeft: 8,
                                    fontFamily: 'Helvetica',
                                    fontSize: 8,
                                 
                                  }}>{item.Medicine}</Text>
                                  <Text style={{

                                    width: '20%',
                                    textAlign: 'center',
                                    paddingLeft: 10,
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    borderLeftWidth:1,
                                    paddingRight: 10,
                                    fontFamily: "Helvetica-Bold",
                                    fontSize: 10,
                                    lineHeight: 1.4

                                  }}>{item.Result}</Text>


                                </View>
                              </View>

                            })
                          }
                        </View>
                      })}
                      </View>
                      <View style={{width:"10%"}}>
                        </View>
                      <View style={{width:"45%"}}>
                      <View style={{width:"100%"}}>
                      {this.state.data!=undefined && Object.keys(this.state.data.Germs).length>4 ? <MedicineHeader  data={this.state.data}/> : null}
                      </View>

                        {Object.keys(this.state.data.Germs).map((germKey) => {
                             
                             if(GermCounter==100){
                              GermCounter=1
                              return null
                            }else if(GermCounter<4){
                              GermCounter++
                              return null
                            }

                          return <View style={{ marginTop: 5 }}>
                            <Text style={styles.MedicineGroupName}>{germKey}</Text>
                            {
                              this.state.data.Germs[germKey].map((item) => {
                                return <View>

                                  <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderWidth: 0.5,
                                    lineHeight: 1,
                                    fontSize: 7,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    margin: 1
                                  }} key={item.Medicine}>

                                    <Text style={{

                                      width: '80%',
                                      textAlign: 'left',
                                      paddingTop: 1,
                                      paddingBottom: 1,
                                      paddingLeft: 8,
                                      fontFamily: 'Helvetica',
                                      fontSize: 8,
                                    }}>{item.Medicine}</Text>
                                    <Text style={{

                                      width: '20%',
                                      textAlign: 'center',
                                      paddingLeft: 10,
                                      paddingTop: 1,
                                      paddingBottom: 1,
                                      borderLeftWidth:1,
                                      paddingRight: 10,
                                      fontFamily: "Helvetica-Bold",
                                      fontSize: 10,
                                      lineHeight: 1.4

                                    }}>{item.Result}</Text>


                                  </View>
                                </View>

                              })
                            }
                          </View>
                        })}
                      </View>
                  </View>
             
                    <View style={{
                      paddingTop:10,
                      paddingLeft:10,
                      marginTop:10,
                      paddingBottom:10,
                      backgroundColor:"#eeeeee",
                      //  backgroundColor:"red",
                      borderWidth:0.5
                    }}>
                    <Text style={{
                       fontFamily: 'Helvetica-Bold',
                       fontSize: 8
                    }}>{"Pathologist Remarks"}</Text>
                    <View style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "100%",
                        fontSize: 7,
                        paddingTop: 10
                        
  
                      }}
                      >
                        {
                          this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                            this.state.line = this.state.line + 1
                            var space = this.state.line > 28
                            if (data.length > 200) {
                              this.state.line = this.state.line + 1
                            }
  
                            if (data == "") {
                              return <View><Text>{""}</Text></View>
                            }
  
  
                            if (space) {
  
                              return <View style={{
                                marginTop: 1,
                                width: "100%",
                                // paddingLeft:""
                                paddingRight:"55%"
                              }}>
                                <View></View>
                                <Text style={{
                                  textAlign: "left"
                                }}>{data}</Text>
                              </View>
  
                            }
  
                            return <View style={{
                              marginTop: 1,
                              width: "100%",
                            // 10-may-2024 sir nadeem share reports 5702377
                              // paddingRight:"55%"
                              
                              paddingRight:this.state.data.Microbiology[key].length==2?"1.5%":"55%"
                            }}>
                              <View></View>
                              <Text style={{
                                textAlign: "left"
                              }}>{data}</Text>
                            </View>
                          })
                        }
  
                      </View>
                   </View>
                 
              </View>
              : 


              <View>
              <View style={{marginTop:"5em"}}>
               <Text style={styles.SusProfileTitle}>{"SUSCEPTIBILITY PROFILE"}</Text>
             </View>
               <View style={{ flexDirection:"row", width:"100%"}}>
                 <View style={{width:"100%"}}>
                   <View style={{width:"100%"}}>
                   {this.state.data!=undefined ? <MedicineHeader  data={this.state.data}/> : null}
                   </View>

                   {Object.keys(this.state.data.Germs).map((germKey) => {
                   
                    
                     return <View style={{ marginTop: 5 }}>
                        <View break={true}></View>
                       <Text style={styles.MedicineGroupName}>{germKey}</Text>
                       {
                         this.state.data.Germs[germKey].map((item) => {
                          
                           return <View>
                             <View style={{
                               flexDirection: 'row',
                               alignItems: 'center',
                               borderWidth: 0.5,
                               lineHeight: 1,
                               fontSize: 7,
                               paddingTop: 2,
                               paddingBottom: 2,
                               margin: 1
                             }} key={item.Medicine}>

                               <Text style={{

                                 width: (this.state.data.MB_Germ1!="" && this.state.data.MB_Germ2!="" && this.state.data.MB_Germ3!="" && this.state.data.MB_Germ4!="" && this.state.data.MB_Germ5!="") ? '25%' : (this.state.data.MB_Germ1!="" && this.state.data.MB_Germ2!="" && this.state.data.MB_Germ3!="" && this.state.data.MB_Germ4!="" && this.state.data.MB_Germ5=="") ? "40%" : (this.state.data.MB_Germ1!="" && this.state.data.MB_Germ2!="" && this.state.data.MB_Germ3!="" && this.state.data.MB_Germ4=="" && this.state.data.MB_Germ5=="") ? '55%' : (this.state.data.MB_Germ1!="" && this.state.data.MB_Germ2!="" && this.state.data.MB_Germ3=="" && this.state.data.MB_Germ4=="" && this.state.data.MB_Germ5=="") ? '70%' : "85%",
                                 textAlign: 'left',
                                 paddingTop: 1,
                                 paddingBottom: 1,
                                 paddingLeft: 8,
                                 fontFamily: 'Helvetica',
                                 fontSize: 8,
                               }}>{item.Medicine}</Text>
                               <Text style={{

                                 width: '15%',
                                 textAlign: 'center',
                                 paddingLeft: 10,
                                 paddingTop: 1,
                                 paddingBottom: 1,
                                 borderLeftWidth:1,
                                 paddingRight: 10,
                                 height:"100%",
                                 fontFamily: "Helvetica-Bold",
                                 fontSize: 10,
                                 lineHeight: 1.4

                               }}>{item.Result}</Text>
                               {
                                 this.state.data.MB_Germ2!=""
                                 ?
                                 <Text style={{

                                  width: '15%',
                                  textAlign: 'center',
                                  paddingLeft: 10,
                                  paddingTop: 1,
                                  paddingBottom: 1,
                                  height:"100%",
                                  borderLeftWidth:1,
                                  paddingRight: 10,
                                  fontFamily: "Helvetica-Bold",
                                  fontSize: 10,
                                  lineHeight: 1.4
 
                                }}>{item.Result2}</Text>
                                :null
                               }
                                  {
                                 this.state.data.MB_Germ3!=""
                                 ?
                                 <Text style={{

                                  width: '15%',
                                  textAlign: 'center',
                                  paddingLeft: 10,
                                  paddingTop: 1,
                                  paddingBottom: 1,
                                  borderLeftWidth:1,
                                  paddingRight: 10,
                                  fontFamily: "Helvetica-Bold",
                                  fontSize: 10,
                                  lineHeight: 1.4
 
                                }}>{item.Result3}</Text>
                                :null
                               }
                                  {
                                 this.state.data.MB_Germ4!=""
                                 ?
                                 <Text style={{

                                  width: '15%',
                                  textAlign: 'center',
                                  paddingLeft: 10,
                                  paddingTop: 1,
                                  paddingBottom: 1,
                                  borderLeftWidth:1,
                                  paddingRight: 10,
                                  fontFamily: "Helvetica-Bold",
                                  fontSize: 10,
                                  lineHeight: 1.4
 
                                }}>{item.Result4}</Text>
                                :null
                               }
                                  {
                                 this.state.data.MB_Germ5!=""
                                 ?
                                 <Text style={{

                                  width: '15%',
                                  textAlign: 'center',
                                  paddingLeft: 10,
                                  paddingTop: 1,
                                  paddingBottom: 1,
                                  borderLeftWidth:1,
                                  paddingRight: 10,
                                  fontFamily: "Helvetica-Bold",
                                  fontSize: 10,
                                  lineHeight: 1.4
 
                                }}>{item.Result5}</Text>
                                :null
                               }


                             </View>
                           </View>

                         })
                       }
                     </View>
                   })}
                   </View>
               </View>
               <View style={{
                 paddingTop:10,
                 paddingLeft:10,
                 marginTop:10,
                 paddingBottom:10,
                 backgroundColor:"#eeeeee",
                //  backgroundColor:"green",
                 borderWidth:0.5
               }}>
               <Text style={{
                  fontFamily: 'Helvetica-Bold',
                  fontSize: 8
               }}>{"Pathologist Remarks"}</Text>
               <View style={{
                   flexDirection: 'column',
                   alignItems: 'center',
                   width: "100%",
                   fontSize: 7,
                   paddingTop: 10
                   

                 }}
                 >
                   {
                     this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                       this.state.line = this.state.line + 1
                       var space = this.state.line > 28
                       if (data.length > 200) {
                         this.state.line = this.state.line + 1
                       }

                       if (data == "") {
                         return <View><Text>{""}</Text></View>
                       }


                       if (space) {

                         return <View style={{
                           marginTop: 1,
                           width: "100%",
                         }}>
                           <View></View>
                           <Text style={{
                             textAlign: "left"
                           }}>{data}</Text>
                         </View>

                       }

                       return <View style={{
                         marginTop: 1,
                         width: "100%",
                       }}>
                         <View></View>
                         <Text style={{
                           textAlign: "left"
                         }}>{data}</Text>
                       </View>
                     })
                   }

                 </View>
              </View>
           </View>
             }
              </View>
            </View>

      </View>


    }))}
    </View>
    
  }
}



export default InvoiceItemsTable